import { Component, OnInit } from '@angular/core';
import { DataService } from 'src/app/data.service';

declare const Swal: any;

declare const AOS: any;
@Component({
  selector: 'app-odkazy',
  templateUrl: './odkazy.component.html',
  styleUrls: ['./odkazy.component.scss'],
})
export class OdkazyComponent implements OnInit {
  constructor(public data: DataService) {}

  ngOnInit() {
    AOS.init({
      duration: 600,
      delay: 300,
    });
  }

  onMcClick() {
    const str = 'mc.gallactic.net';
    const el = document.createElement('textarea');
    el.value = str;
    document.body.appendChild(el);
    el.select();
    document.execCommand('copy');
    document.body.removeChild(el);

    Swal.fire({
      icon: 'success',
      title: 'IP adresa zkopírována',
      text: 'Teď můžeš vložit IP adresu do Minecraftu.',
    });
  }
}
