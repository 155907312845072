import { Component, OnInit } from '@angular/core';

declare const AOS: any;
declare const Swal: any;
@Component({
  selector: 'app-game',
  templateUrl: './game.component.html',
  styleUrls: ['./game.component.scss'],
})
export class GameComponent implements OnInit {
  data = null;
  isGenerating = false;

  constructor() { }

  async onUpdateLeaderboard() {
    this.data = null;
    // this.data = await APIGame.getLeaderboard();
    // this.data.players = this.data.players.filter((p) => {
    //   return p.nick.indexOf('Unnamed') === -1;
    // });
  }

  async ngOnInit() {
    AOS.init({
      duration: 600,
      delay: 300,
    });

    await this.onUpdateLeaderboard();
  }

  onScroll() {
    document.getElementById('pingen').scrollIntoView();
  }

  async onSubmit(e, target) {
    e.preventDefault();

    // this.isGenerating = true;

    // const val = target.value;

    // if (!val) {
    //   Swal.fire({
    //     icon: 'error',
    //     title: 'Chyba generace',
    //     text: 'Prosím zadej nick do textového pole.',
    //   });

    //   this.isGenerating = false;
    //   return;
    // }

    // try {
    //   const res = await APIGame.generatePin(
    //     {},
    //     {
    //       nick: val,
    //     },
    //   );

    //   Swal.fire({
    //     icon: 'success',
    //     title: 'PIN BYL VYGENEROVANÝ',
    //     html:
    //       'Tvůj PIN je:<br><span style="font-weight: bolder">' +
    //       res.message +
    //       '</span><br><br>Pod jedním PINem může hrát více hráčů.<br>Může ti tedy pomoci navyšovat score i tvůj kamarád.',
    //   });

    //   this.isGenerating = false;
    //   await this.onUpdateLeaderboard();
    // } catch (exp) {
    //   Swal.fire({
    //     icon: 'error',
    //     title: 'Serverová chyba',
    //     text:
    //       'Na serveru se stalo něco neočekávané, omlouváme se. Kontaktuj prosim Kellwiina.',
    //   });

    //   this.isGenerating = false;
    // }
  }
}
