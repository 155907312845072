import { Component, OnInit } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import API from 'src/services/API';
import { environment } from 'src/environments/environment';

declare const AOS: any;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  constructor(private router: Router) {}

  ngOnInit() {
    AOS.init({
      duration: 600,
      delay: 300,
    });

    this.router.events.subscribe(evt => {
      if (!(evt instanceof NavigationEnd)) {
        return;
      }
      window.scrollTo(0, 0);
    });
  }
}
