import { Component, OnInit } from '@angular/core';
import { DataService } from 'src/app/data.service';

declare const loadCircles: any;
declare const $: any;
declare const close_video: any;
declare const AOS: any;

@Component({
  selector: 'app-index',
  templateUrl: './index.component.html',
  styleUrls: ['./index.component.scss'],
})
export class IndexComponent implements OnInit {
  constructor(public data: DataService) {}

  ngOnInit() {
    AOS.init({
      duration: 600,
      delay: 300,
    });

    loadCircles();

    const playBttn = $('#play-video');
    const playBtnOverlay = $('#video-overlay');
    const playBtnOverlayClose = $('.video-overlay, .video-overlay-close');
    playBttn.on('click', e => {
      e.preventDefault();
      playBtnOverlay.addClass('open');
    });
    playBtnOverlayClose.on('click', e => {
      e.preventDefault();
      close_video();
    });
    playBttn.modalVideo();
  }
}
