import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-kalendar',
  templateUrl: './kalendar.component.html',
  styleUrls: ['./kalendar.component.scss'],
})
export class KalendarComponent implements OnInit {
  constructor() {}

  ngOnInit() {}
}
