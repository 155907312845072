import { Component, OnInit } from '@angular/core';
import { DataService } from 'src/app/data.service';

declare const AOS: any;

@Component({
  selector: 'app-merch',
  templateUrl: './merch.component.html',
  styleUrls: ['./merch.component.scss'],
})
export class MerchComponent implements OnInit {
  constructor(public data: DataService) {}

  ngOnInit() {
    AOS.init({
      duration: 600,
      delay: 300,
    });
  }
}
