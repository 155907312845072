import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import API from 'src/services/API';

declare const Swal: any;

@Injectable({
  providedIn: 'root',
})
export class DataService {
  isOnline = false;

  // clips = ['SaltyColorfulAdminRalpherZ', 'GeniusBusyCheetahDoggo'];
  clipsArr = [];

  motd = 'Život se má užívat, né žít. 💙';

  fbUrl = 'https://facebook.com/Kellwiin';
  twitchUrl = 'https://www.twitch.tv/kellwiin';
  igUrl = 'https://www.instagram.com/_kellwiin_/';
  ytUrl = 'https://www.youtube.com/channel/UC4fkdzg2e8-Po1JqUcA-3iA';
  discordUrl = 'https://discord.com/invite/7uGBYee';
  mailUrl = 'mailto:kellwiin.spoluprace@gmail.com';

  redditUrl = 'https://www.reddit.com/r/Kellwiin/';
  darkTigersUrl = 'http://www.darktigers.cz/';

  // light first, then dark
  blogColors = {
    red: ['#e83c3c', '#c33232'],
    blue: ['#0dd0ef', '#0f92a7'],
    green: ['#08d665', '#0aad54'],
    orange: ['#f5ab04', '#d49507'],
  };
  blogPosts = [];
  // blogPosts = [
  //   {
  //     game: 'FORTNITE',
  //     color: this.blogColors.blue,
  //     date: new Date(),
  //     addedBy: 'Kellwiin',
  //     text:
  //       'Dnesny stream obsahuje drobnu zmenu, fortnite predlzime o hodinku a CSko nebude vobec!',
  //   },
  //   {
  //     game: 'CS:GO',
  //     color: this.blogColors.red,
  //     date: new Date(),
  //     addedBy: 'Kellwiin',
  //     text: 'Dnes cepy den cskooo, hypeee, cecky cecky !!!',
  //   },
  // ];

  gearGroups = [
    {
      title: 'příslušenství',
      items: [
        {
          preTitle: null,
          title: 'Světla',
          postTitle: null,
          imgSrc: '/assets/img/gear/1.jpg',
          name: 'Nanoleaf Light Panels',
          btnUrl:
            'https://nanoleaf.me/en/products/smarter-series/nanoleaf-light-panels-smarter-kit/',
          btnText: 'Koupit na nanoleaf.me',
        },
        {
          preTitle: 'Ergonomický',
          title: 'stůl',
          postTitle: null,
          imgSrc: '/assets/img/gear/2.jpg',
          name: 'stůl na míru',
          btnUrl: 'http://www.jmk-system.cz',
          btnText: 'Koupit na jmk-system.cz',
        },
        {
          preTitle: 'Hlavní',
          title: 'monitor',
          postTitle: null,
          imgSrc: '/assets/img/gear/3.jpg',
          name: 'ROG Swift PG27VQ - 27-palcový 2K WQHD',
          btnUrl: 'https://www.asus.com/cz/Monitors/ROG-SWIFT-PG27VQ/',
          btnText: 'Koupit na asus.com',
        },
        {
          preTitle: 'vedlejší',
          title: 'monitor',
          postTitle: null,
          imgSrc: '/assets/img/gear/4.webp',
          name: 'Dell 27 Gaming Monitor: S2716DG',
          btnUrl:
            'https://www.dell.com/en-us/shop/dell-27-gaming-monitor-s2716dg/apd/210-agjr/monitors-monitor-accessories',
          btnText: 'Koupit na dell.com',
        },
        {
          preTitle: null,
          title: 'Myš',
          postTitle: null,
          imgSrc: '/assets/img/gear/5.jpg',
          name: 'ROG Spartha',
          btnUrl: 'https://www.asus.com/ROG-Republic-Of-Gamers/ROG-Spatha/',
          btnText: 'Koupit na asus.com',
        },
        {
          preTitle: null,
          title: 'Klávesnice',
          postTitle: null,
          imgSrc: '/assets/img/gear/6.png',
          name: 'ROG Strix Flare RGB',
          btnUrl:
            'https://www.asus.com/cz/ROG-Republic-Of-Gamers/ROG-Strix-Flare/',
          btnText: 'Koupit na asus.com',
        },
        {
          preTitle: null,
          title: 'Podložka',
          postTitle: null,
          imgSrc: '/assets/img/gear/7.jpg',
          name: 'ROG Sheath',
          btnUrl: 'https://www.asus.com/cz/ROG-Republic-Of-Gamers/ROG-Sheath/',
          btnText: 'Koupit na asus.com',
        },
        {
          preTitle: null,
          title: 'Sluchátka',
          postTitle: null,
          imgSrc: '/assets/img/gear/8.jpg',
          name: 'ROG Delta Core',
          btnUrl:
            'https://www.asus.com/cz/ROG-Republic-Of-Gamers/ROG-Delta-Core/',
          btnText: 'Koupit na asus.com',
        },

        {
          preTitle: null,
          title: 'Zvuková karta',
          postTitle: null,
          imgSrc: '/assets/img/gear/zvuk.jpg',
          name: 'GO-XLR',
          btnUrl:
            'https://www.thomann.de/cz/tc_helicon_go_xlr.htm?glp=1&gclid=Cj0KCQjw2PP1BRCiARIsAEqv-pTQooaNqzxtYq4vYXwHLUmiBeJYZnME9qSD0hhPDhCvM4KJN5LG8wYaAuGFEALw_wcB',
          btnText: 'Koupit na thomann.de',
        },

        {
          preTitle: '',
          title: 'Studiový mikrofon',
          postTitle: null,
          imgSrc: '/assets/img/gear/mic.jpg',
          name: 'SHURE SM7B',
          btnUrl:
            'https://www.music-city.cz/shure-sm7b-am0009795?gclid=Cj0KCQjw2PP1BRCiARIsAEqv-pQFaw2v2-uiXTtg2HZEiyjaKJizjnAFNckB_0L5kjeWcd5GXSaSk_EaAryoEALw_wcB',
          btnText: 'Koupit na music-city.cz',
        },

        {
          preTitle: '',
          title: 'Webkamera',
          postTitle: null,
          imgSrc: '/assets/img/gear/webcam.jpg',
          name: 'Logitech BRIO ',
          btnUrl:
            'https://www.alza.cz/logitech-brio-d4656399.htm?kampan=adw4_prislusenstvi-pro-it-tv_pla_all_prislusenstvi-css_webkamery_c_1003799__JS038b2&gclid=Cj0KCQjw2PP1BRCiARIsAEqv-pR4reJorTFuKfskAppFMmG5yeTx5fgU0Rx3PZwIwGpbI3xxLRkNdIUaAgv7EALw_wcB',
          btnText: 'Koupit na alza.cz',
        },
      ],
    },
    {
      title: 'Železo',
      items: [
        {
          preTitle: null,
          title: 'Case',
          postTitle: null,
          imgSrc: '/assets/img/gear/9.jpg',
          name: 'ROG Strix Helios RGB ATX/EATX',
          btnUrl:
            'https://www.asus.com/cz/ROG-Republic-Of-Gamers/ROG-Strix-Helios/',
          btnText: 'Koupit na asus.com',
        },
        {
          preTitle: 'Základní',
          title: 'deska',
          postTitle: null,
          imgSrc: '/assets/img/gear/10.jpg',
          name: 'Intel Z390 ATX',
          btnUrl:
            'https://www.asus.com/cz/Motherboards/ROG-MAXIMUS-XI-FORMULA/',
          btnText: 'Koupit na asus.com',
        },
        {
          preTitle: null,
          title: 'RAM',
          imgSrc: '/assets/img/gear/11.png',
          name: 'F4-3200C16D-32GTZR',
          btnUrl:
            'https://www.gskill.com/product/165/166/1536654621/F4-3200C16D-32GTZRTrident-Z-RGBDDR4-3200MHz-CL16-18-18-38-1.35V32GB-(2x16GB)',
          btnText: 'Koupit na gskill.com',
        },
        {
          preTitle: null,
          title: 'Procesor',
          postTitle: null,
          imgSrc: '/assets/img/gear/12.png',
          name: 'INTEL® CORE™ i9-9900K PROCESSOR',
          btnUrl:
            'https://www.intel.com/content/www/us/en/products/processors/core/core-vpro/i9-9900k.html?wapkw=intel%20core%20i9%209900k',
          btnText: 'Koupit na intel.com',
        },
        {
          preTitle: null,
          title: 'Chlazení',
          postTitle: null,
          imgSrc: '/assets/img/gear/13.png',
          name: 'Kraken X72',
          btnUrl: 'https://www.nzxt.com/products/kraken-x72',
          btnText: 'Koupit na nzxt.com',
        },
        {
          preTitle: null,
          title: 'SSD',
          postTitle: null,
          imgSrc: '/assets/img/gear/14.webp',
          name: '970 PRO NVMe M.2 SSD 512 GB',
          btnUrl:
            ' https://www.samsung.com/cz/memory-storage/970-pro-nvme-m2-ssd/MZ-V7P512BW/',
          btnText: 'Koupit na samsung.com',
        },
        {
          preTitle: null,
          title: 'SSD',
          postTitle: null,
          imgSrc: '/assets/img/gear/15.jpg',
          name: 'HyperX FURY SSD 500 GB s RGB podsvícením',
          btnUrl: 'https://www.hyperxgaming.com/en/storage/fury-rgb-ssd',
          btnText: 'Koupit na hyperxgaming.com',
        },
        {
          preTitle: 'Grafická',
          title: 'karta',
          postTitle: null,
          imgSrc: '/assets/img/gear/16.jpg',
          name: 'ROG Strix GeForce RTX™ 2080 Ti Advance edition 11GB GDDR6',
          btnUrl:
            'https://www.asus.com/cz/Graphics-Cards/ROG-STRIX-RTX2080TI-A11G-GAMING/',
          btnText: 'Koupit na asus.com',
        },
        {
          preTitle: null,
          title: 'Zdroj',
          postTitle: null,
          imgSrc: '/assets/img/gear/17.png',
          name: 'EVGA SuperNOVA 1000 G3, 80 Plus Gold 1000W',
          btnUrl:
            'https://www.evga.com/products/product.aspx?pn=220-G3-1000-X1',
          btnText: 'Koupit na evga.com',
        },
      ],
    },
  ];

  merchProducts = [
    {
      tag: 'novinka',
      title: 'Tricko Cecky',
      url: 'https://google.sk/aaa',
      addDate: '20 september 2019',
      desc:
        'Tricko s ceckami bla bla bla, bla bla bla, o tom fakt pisat nechcem bo to nechtiac neodstranim a bude tu bdsm content.',
    },
    {
      tag: 'odporucane',
      title: 'Bunda Cecky',
      url: 'https://google.sk/aaa',
      addDate: '5 november 2019',
      desc:
        'Budna s ceckami bla bla bla, bla bla bla, o tom fakt pisat nechcem bo to nechtiac neodstranim a bude tu bdsm content.',
    },
    {
      tag: null,
      title: 'Podprsenka Cecky',
      url: 'https://google.sk/aaa',
      addDate: '2 februar 2019',
      desc:
        'Podprsenka s ceckami bla bla bla, bla bla bla, o tom fakt pisat nechcem bo to nechtiac neodstranim a bude tu bdsm content.',
    },
  ];

  links = [
    {
      title: 'Instagram',
      desc: 'Instagram Profil',
      href: this.igUrl,
      classes: ['fab', 'fa-instagram'],
    },
    {
      title: 'Twitch',
      desc: 'Twitch Kanál',
      href: this.ytUrl,
      classes: ['fab', 'fa-twitch'],
    },
    {
      title: 'Youtube',
      desc: 'Youtube Kanál',
      href: this.ytUrl,
      classes: ['fab', 'fa-youtube'],
    },
    {
      title: 'Discord',
      desc: 'Discord',
      href: this.discordUrl,
      classes: ['fab', 'fa-discord'],
    },

    {
      title: 'Mail',
      desc: 'Bussiness Mail',
      href: this.mailUrl,
      classes: ['fa', 'fa-envelope'],
    },

    {
      title: 'Facebook',
      desc: 'Facebook Stránka',
      href: this.fbUrl,
      classes: ['fab', 'fa-facebook'],
    },
    {
      title: 'Reddit',
      desc: 'Subreddit',
      href: this.redditUrl,
      classes: ['fab', 'fa-reddit'],
    },

    {
      title: 'Dark Tigers',
      desc: 'Content Creator',
      href: this.darkTigersUrl,
      customImg: '/assets/img/dt.png',
    },
  ];

  private errHandler(err: any) {
    console.log(err);
    Swal.fire({
      icon: 'error',
      title: 'Server error',
      text:
        'Na serveru se stalo něco neočekávané, omlouváme se. Web nemusí být plně funkční.',
    });
  }

  async fetchStatus() {
    this.isOnline = true;
    // try {
    //   const online = await API.getStatus();
    //   this.isOnline = online.success;
    // } catch (err) {
    //   this.errHandler(err);
    // }


    this.http
      .get(
        'https://api.twitch.tv/kraken/streams/43505281',
        {
          headers: {
            Accept: 'application/vnd.twitchtv.v5+json',
            'Client-ID': '3zzmx0l2ph50anf78iefr6su9d8byj8',
          },
        },
      )
      .subscribe(
        (s: any) => {
          this.isOnline = s.stream ? true : false;
          console.log(s);
        },
        (err) => {
          if (err) {
            Swal.fire({
              icon: 'error',
              title: 'Twitch error',
              text: 'Klipy se nepodařilo načíst, prosím zkuste to později.',
            });
          }
        },
      );
  }

  async fetchPosts() {
    this.blogPosts = [];
    // try {
    //   const res = await API.getPosts();
    //   this.blogPosts = res.posts.map((d) => {
    //     return {
    //       ...d,
    //       color: this.blogColors[d.color],
    //     };
    //   });
    // } catch (err) {
    //   this.errHandler(err);
    // }
  }

  async fetchClips() {
    // const promises = this.clips.map(c => {
    //   return new Promise(p => {
    //     this.http
    //       .get('https://api.twitch.tv/kraken/clips/' + c, {
    //         headers: {
    //           Accept: 'application/vnd.twitchtv.v5+json',
    //           'Client-ID': '3zzmx0l2ph50anf78iefr6su9d8byj8',
    //         },
    //       })
    //       .subscribe(
    //         s => {
    //           p(s);
    //         },
    //         err => {
    //           if (err) {
    //             Swal.fire({
    //               icon: 'error',
    //               title: 'Twitch error',
    //               text:
    //                 'Klipy se nepodařilo načíst, prosím zkuste to později.',
    //             });
    //           }
    //         },
    //       );
    //   });
    // });
    // this.clipsArr = await Promise.all(promises);

    this.http
      .get(
        'https://api.twitch.tv/kraken/clips/top/?channel=kellwiin&limit=10&period=week',
        {
          headers: {
            Accept: 'application/vnd.twitchtv.v5+json',
            'Client-ID': '3zzmx0l2ph50anf78iefr6su9d8byj8',
          },
        },
      )
      .subscribe(
        (s: any) => {
          this.clipsArr = s.clips;
        },
        (err) => {
          if (err) {
            Swal.fire({
              icon: 'error',
              title: 'Twitch error',
              text: 'Klipy se nepodařilo načíst, prosím zkuste to později.',
            });
          }
        },
      );
  }

  constructor(private http: HttpClient) {
    this.fetchStatus();
    this.fetchPosts();
    this.fetchClips();
  }
}
