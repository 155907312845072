import { Component, OnInit } from '@angular/core';
import { DataService } from '../data.service';

@Component({
  selector: 'app-header-banner',
  templateUrl: './header-banner.component.html',
  styleUrls: ['./header-banner.component.scss'],
})
export class HeaderBannerComponent implements OnInit {
  constructor(public data: DataService) {}

  ngOnInit() {}
}
