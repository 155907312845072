import { Component, OnInit } from '@angular/core';

declare const $bos: any;

@Component({
  selector: 'app-freevb',
  templateUrl: './freevb.component.html',
  styleUrls: ['./freevb.component.scss'],
})
export class FreevbComponent implements OnInit {
  constructor() {}

  ngOnInit() {
    // $bos('myDiv', { id: '15058', width: '640', height: '360' }, function () {
    //   console.log('Start');
    //   this.add('adError', (err) => {
    //     console.log(err);
    //     console.log('ERr');
    //   });
    // });
  }
}
