import { Component, OnInit } from '@angular/core';

declare const AOS: any;
@Component({
  selector: 'app-divaci',
  templateUrl: './divaci.component.html',
  styleUrls: ['./divaci.component.scss'],
})
export class DivaciComponent implements OnInit {
  constructor() {}

  ngOnInit() {
    AOS.init({
      duration: 600,
      delay: 300,
    });
  }
}
