import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { IndexComponent } from './pages/index/index.component';
import { MerchComponent } from './pages/merch/merch.component';
import { GearComponent } from './pages/gear/gear.component';
import { OdkazyComponent } from './pages/odkazy/odkazy.component';
import { KalendarComponent } from './pages/kalendar/kalendar.component';
import { BlogComponent } from './pages/blog/blog.component';
import { ClipsComponent } from './pages/clips/clips.component';
import { DivaciComponent } from './divaci/divaci.component';
import { GameComponent } from './game/game.component';
import {} from './adblock.guard';
import { AdblockComponent } from './adblock/adblock.component';
import { FreevbComponent } from './freevb/freevb.component';

// ad block guard

const routes: Routes = [
  {
    path: '',
    component: IndexComponent,
    canActivate: [],
  },
  {
    path: 'blog',
    component: BlogComponent,
    canActivate: [],
  },
  {
    path: 'hra',
    component: GameComponent,
    canActivate: [],
  },
  {
    path: 'odkazy',
    component: OdkazyComponent,
    canActivate: [],
  },
  {
    path: 'gear',
    component: GearComponent,
    canActivate: [],
  },
  {
    path: 'merch',
    component: MerchComponent,
    canActivate: [],
  },
  {
    path: 'kalendar',
    component: KalendarComponent,
    canActivate: [],
  },
  {
    path: 'klipy',
    component: ClipsComponent,
    canActivate: [],
  },
  {
    path: 'divaci',
    component: DivaciComponent,
    canActivate: [],
  },
  {
    path: 'freevb',
    component: FreevbComponent,
    canActivate: [],
  },
  {
    path: 'adblock',
    component: AdblockComponent,
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
