import { Component, OnInit } from '@angular/core';
import { DataService } from 'src/app/data.service';
import { DomSanitizer } from '@angular/platform-browser';

declare const AOS: any;
declare const $: any;

@Component({
  selector: 'app-clips',
  templateUrl: './clips.component.html',
  styleUrls: ['./clips.component.scss'],
})
export class ClipsComponent implements OnInit {
  currentClipUrl = null;
  visible = false;

  constructor(public data: DataService, private sanitizer: DomSanitizer) {}

  onClose() {
    setTimeout(() => {
      this.visible = false;
    }, 800);
  }

  ngOnInit() {
    AOS.init({
      duration: 600,
      delay: 300,
    });

    $('#twitchModal').on('hidden', () => {
      this.visible = false;
    });
  }

  setClip(url: string) {
    this.currentClipUrl = url;
    this.visible = true;
  }

  isVisible() {
    return this.visible;
  }

  safe(url) {
    return this.sanitizer.bypassSecurityTrustResourceUrl(url);
  }
}
