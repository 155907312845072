import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HeaderBannerComponent } from './header-banner/header-banner.component';
import { HeaderComponent } from './header/header.component';
import { FooterComponent } from './footer/footer.component';
import { IndexComponent } from './pages/index/index.component';
import { GearComponent } from './pages/gear/gear.component';
import { KalendarComponent } from './pages/kalendar/kalendar.component';
import { MerchComponent } from './pages/merch/merch.component';
import { BlogComponent } from './pages/blog/blog.component';
import { OdkazyComponent } from './pages/odkazy/odkazy.component';
import { ClipsComponent } from './pages/clips/clips.component';
import { DivaciComponent } from './divaci/divaci.component';
import { GameComponent } from './game/game.component';
import { AdblockComponent } from './adblock/adblock.component';
import { FreevbComponent } from './freevb/freevb.component';

// API.setBaseUrl(environment.api);
// APIGame.setBaseUrl(environment.apiGame);

@NgModule({
  declarations: [
    AppComponent,
    HeaderBannerComponent,
    HeaderComponent,
    FooterComponent,
    IndexComponent,
    GearComponent,
    KalendarComponent,
    MerchComponent,
    BlogComponent,
    OdkazyComponent,
    ClipsComponent,
    DivaciComponent,
    GameComponent,
    AdblockComponent,
    FreevbComponent,
  ],
  imports: [BrowserModule, AppRoutingModule, HttpClientModule],
  providers: [],
  bootstrap: [AppComponent],
})
export class AppModule { }
