import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

declare const AOS: any;

@Component({
  selector: 'app-adblock',
  templateUrl: './adblock.component.html',
  styleUrls: ['./adblock.component.scss'],
})
export class AdblockComponent implements OnInit {
  showLoading = false;
  constructor(private route: ActivatedRoute, private router: Router) {}

  ngOnInit() {
    this.showLoading = false;
    AOS.init({
      duration: 600,
      delay: 300,
    });
  }

  onRedirect() {
    this.showLoading = true;
    setTimeout(() => {
      const from = this.route.snapshot.queryParams.from;
      window.location.href = from;
    }, 1000);
  }
}
